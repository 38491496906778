<template>
  <div>
    <p class="fs-16 mb-2">Размещение</p>
    <b-radio-group
      class="mb-2"
      :disabled="disabled"
      stacked
      :options="publicationSchemeChoices"
      v-model="gathering.publication_scheme"
    ></b-radio-group>
  </div>
</template>

<script>
import PrGatheringItemMixin from '@main/mixins/PrGatheringItemMixin.vue';

export default {
  mixins: [PrGatheringItemMixin],
};
</script>

<style scoped lang="scss">
::v-deep .custom-control-label {
  position: relative !important;
  margin-bottom: 0 !important;
  vertical-align: top !important;
  background: transparent !important;
}

::v-deep .custom-radio .custom-control-label::after {
  width: 24px;
  display: none;
  top: 7px;
  top: 0.2px;
  left: 5.1px;
  height: 24px;
}

::v-deep .custom-radio .custom-control-label {
  border: none !important;
  max-width: 300px !important;
  width: 100% !important;
  padding-right: 20px !important;
  height: 24px !important;
  margin-bottom: 3px !important;
  margin-right: 20px !important;
}
::v-deep .custom-radio .custom-control-label::before {
  top: 4.5px !important;
  left: 0px !important;
}
::v-deep .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  top: 4.5px !important;
  border: 3px double white;
}
::v-deep .custom-radio .custom-control-label {
  padding-left: 24px !important;
}

::v-deep .custom-control {
  padding-left: 0px !important;
}
</style>
