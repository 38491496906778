<script>
import number from '@main/func/number';
import moment from 'moment';
import prGathering from '@main/api/prGathering';

export default {
  props: {
    gathering: Object,
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    publicationSchemeChoices: [
      {
        value: '1',
        text: 'Каждый день',
      },
      {
        value: '01',
        text: 'Через день',
      },
      {
        value: '1111100',
        text: 'По будням',
      },
    ],
    excludedHours: [],
  }),
  model: {
    prop: 'gathering',
    event: 'input',
  },
  watch: {
    gathering: {
      async handler(data) {
        console.log(data);
        await prGathering.gatherings.update(data);
      },
      deep: true,
    },
  },
  methods: {
    setTimeValue(val, place) {
      const ta = this.gathering.time.split(':');
      ta.splice(place, 1, val);
      this.$emit('input', { ...this.gathering, time: ta.join(':') });
    },
  },
  computed: {
    months() {
      return number.range(moment().daysInMonth()).map((n) => ({ value: n + 1, text: n + 1 }));
    },
    hours() {
      return number.range(24).map((n) => ({ value: n, text: `0${n}`.slice(-2) }));
    },
    minutes() {
      return number.range(60).map((n) => ({ value: n, text: `0${n}`.slice(-2) }));
    },
    currentHour() {
      return this.hours.filter((val) => val.text === this.gathering.time.split(':')[0])[0].value;
    },
    currentMinute() {
      return this.minutes.filter((val) => val.text === this.gathering.time.split(':')[1])[0].value;
    },
  },
};
</script>
