<template>
  <modal
    :show="show"
    title="Пример размещения"
    @hide="() => $emit('hide')"
    contentClass="p-4 fs-16 overflow-auto"
  >
    <div
      style="white-space: pre-wrap"
      v-if="currentUser && currentUser.blogger_profile"
      v-html="sample"
    ></div>
    <div v-else>
      Blogger. Охваты сторис ≈ 25628 от 6500 ₽
      <br /><br />
      ⭐️ Блогер — blogger
      <br />
      ✍️ Темы: Мамы, Рецепты и еда, Семья
      <br /><br />
      Охваты сторис ≈ 25 628
      <br />
      Охваты постов ≈ 88 386
      <br /><br />
      📈 Статистика аудитории:
      <br />
      Женщины — 73%
      <br />
      Россия — 75%
      <br />
      25-34 года — 47%
      <br />
      Москва — 36%
      <br /><br />
      📈 Отзывы и карточка блогера
      <br /><br />
      Блогер не рекламирует: Казино, гадалки, игровые автоматы, сетевой
      <br /><br />
      Форматы для личных блогов ⬇️
      <br /><br />
      ▫️Сторис 15 сек. — 6 500 ₽
      <br />
      Данные на август
      <br /><br />
      Форматы для коммерческих аккаунтов ⬇️
      <br /><br />
      ▫️Сторис 75 сек. — 10 000 ₽
      <br />
      Данные на август
      <br />
      1 подводка
      <br />
      3 рекомендаций
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import PrTypeDisplayableMixin from '@main/mixins/statistic/PrTypeDisplayableMixin.vue';
import Modal from '@main/components/reusable/modals/Modal.vue';
import prGathering from '@main/api/prGathering';

export default {
  props: {
    show: Boolean,
  },
  data: () => ({
    sample: null,
  }),
  computed: {
    ...mapState(['currentUser', 'bloggerOptions']),
    minPrice() {
      if (!this.currentUser.blogger_profile.pr_types.length) {
        return;
      }
      return this.currentUser.blogger_profile.pr_types
        .reduce((t, n) => {
          return n <= t ? n : t;
        })
        .uPrice.toLocaleString()
        .replaceAll(',', ' ');
    },
    currentAudience() {
      const { choices } = this.bloggerOptions.actions.POST.audiences.child.children.age_group;
      const values = choices.filter(
        (val) => val.value === this.currentUser.blogger_profile.main_audience.age_group
      );
      return values[0].display_name;
    },
  },
  async mounted() {
    this.sample = await prGathering.gatherings.getSample();
  },
  components: {
    Modal,
  },
};
</script>

<style lang="css" scoped></style>
